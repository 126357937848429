import styled from "styled-components";

export const FullWidthContainer = styled.div`
  overflow: hidden;

  @media (min-width: 81rem) {
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 83rem;
    margin-left: auto;
  }
`;

export const HomepageContainer = styled.div`
  overflow: hidden;
  max-width: 100vw;
`;

export const FeedContainer = styled.div`
  display: grid;
  grid-column-gap: 25px;
  grid-template-columns: 1fr 625px 1fr;
  margin-left: 1rem;
  margin-right: 1rem;

  @media only screen and (max-width: 1024px) {
    display: block;
  }
`;
