export const wrapperVariants = {
  init: {},
  anim: {
    transition: {
      staggerChildren: 0.15,
    },
  },
  exit: {},
  hover: {},
};

export const childVariants = {
  init: {
    y: 50,
    opacity: 0,
  },
  anim: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      ease: [0.25, 0.01, -0.05, 1],
      staggerChildren: 0.15,
    },
  },
  hover: {},
};

export const accesibleVariants = {
  init: {
    opacity: 0,
  },
  anim: {
    opacity: 1,
    transition: {
      duration: 1,
      ease: [0.25, 0.01, -0.05, 1],
      staggerChildren: 0.15,
    },
  },
  hover: {},
};
