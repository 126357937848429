import React from "react";
import styled from "styled-components";
import { BREAKPOINT, TYPE_SIZE, COLORS } from "utils/styles";
import { motion, useReducedMotion } from "framer-motion";
import {
  wrapperVariants,
  childVariants,
  accesibleVariants,
} from "utils/motion";

const Wrapper = styled.section`
  text-align: ${(props) => (props.align ? props.align : "center")};
  padding-top: 3rem;
  margin-top: 3rem;

  &:not(:last-of-type) {
    margin-bottom: 12rem;
  }
  @media ${BREAKPOINT.M} {
    text-align: left;
    margin-bottom: 3rem;
    padding-left: 0.5rem;
  }

  h1 {
    font-size: ${TYPE_SIZE.XL};
    font-weight: 400;
    margin: 0 auto 2rem auto;
    font-family: "mabry-regular";
    @media ${BREAKPOINT.M} {
      font-size: 2rem;
      margin: 0 auto 2rem auto;
    }
  }
  h2 {
    margin-top: 2rem;
  }

  ul {
    font-size: ${TYPE_SIZE.M};
    color: ${COLORS.DARKEST_GRAY};
    @media ${BREAKPOINT.M} {
      font-size: 1rem;
    }
  }

  a {
    color: initial;
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }
  }

  p {
    font-size: ${TYPE_SIZE.M};
    line-height: 137%;
    font-weight: 300;
    max-width: 60rem;
    margin: 0 auto;
    margin-bottom: 1rem;

    @media ${BREAKPOINT.M} {
      font-size: 1rem;
    }
  }

  footer {
    margin-top: 4rem;
  }
`;

const Section = ({ align, children, id }) => {
  const shouldReduceMotion = useReducedMotion();
  return (
    <Wrapper
      id={id}
      as={motion.article}
      variants={wrapperVariants}
      initial="init"
      animate="anim"
      align={align}
    >
      {children.map((child, i) => (
        <motion.div
          key={`child-${i}`}
          variants={shouldReduceMotion ? accesibleVariants : childVariants}
        >
          {child}
        </motion.div>
      ))}
    </Wrapper>
  );
};

export default Section;
