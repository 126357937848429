import { CustomToggle, NavItemIcon } from "./components";

import { BREAKPOINT } from "utils/styles";
import { Dropdown } from "react-bootstrap";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.nav`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1000;
  display: flex;

  @media ${BREAKPOINT.M} {
    display: none;
  }
`;

const NavItemIconOpen = styled(NavItemIcon)`
  display: none;
  .dropdown.show & {
    display: block;
  }
`;
const NavItemIconClosed = styled(NavItemIcon)`
  display: block;
  .dropdown.show & {
    display: none;
  }
`;

const shareUrl = process.browser
  ? window.location.href
  : "https://narrativeobservatory.org/";
const shareText = `Interested in narrative change efforts around poverty and economic mobility in the US? Check out this project from @harmonylabs that explores the audiences for poverty narratives, through the lens of the culture they engage with via media. ${shareUrl}`;

export default function SecondaryNav() {
  return (
    <Wrapper>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <NavItemIconClosed className="material-icons-round">
            share
          </NavItemIconClosed>
          <NavItemIconOpen className="material-icons-round">
            close
          </NavItemIconOpen>
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight>
          <Dropdown.Item
            as="a"
            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
              shareText
            )}`}
            target="blank"
          >
            <img width={18} alt="Twitter" src="/images/twitter-logo.svg" />
          </Dropdown.Item>
          <Dropdown.Item
            as="a"
            href={`https://www.facebook.com/sharer.php?u=${shareUrl}`}
            target="blank"
          >
            <img
              width={20}
              alt="Facebook"
              src="/images/f_logo_RGB-Black_58.png"
            />
          </Dropdown.Item>
          <Dropdown.Item
            as="a"
            href={`https://www.linkedin.com/shareArticle/?url=${shareUrl}`}
            target="blank"
          >
            <img width={18} alt="LinkedIn" src="/images/LI-In-Bug.png" />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Wrapper>
  );
}
